<template>
  <a-card
    :editable="false"
  >
    <template
      #header-buttons
    >
      <b-field
        grouped
      >
        <b-input
          v-model="path"
          expanded
        />
        <p class="control">
          <b-button
            type="is-primary"
            @click="saveTranslation"
          >
            Save translation
          </b-button>
        </p>
        <p class="control">
          <b-button
            type="is-primary"
            @click="backupOnce"
          >
            backupOnce
          </b-button>
        </p>
      </b-field>
    </template>

    <b-table
      v-if="flatLocales"
      ref="table"
      :data="flatLocales"
      :columns="columns"
    />
  </a-card>
</template>

<script>
import _ from 'lodash'
import flatten from 'flat'
const { getUser } = require('@/callables')

const columns = [
  { field: 'path', searchable: true },
  { field: 'translation', searchable: true }
]

export default {
  data() {
    return {
      columns,
      path: ''
    }
  },
  computed: {
    flatLocales() {
      if(this.$store.getters["locales/data"]['en']) {
        const flattened = flatten(this.$store.getters["locales/data"]['en'])
        console.log({ flattened })
        return _.map(flattened, function(translation, path) {
          return {
            path,
            translation
          }
        })
      }
    }
  },
  async created () {
    await this.$store.dispatch(`locales/fetchAll`)
  },
  methods: {
    saveTranslation() {
      const { translation } = this.$refs.table.filters
      const newData = {
        id: 'en',
        [this.path]: translation
      }
      console.log({ newData })
      this.$store.dispatch("locales/patch", newData)
      this.$refs.table.filters.translation = ''
    },
    async backupOnce() {
      /*const response = await fetch('https://us-central1-rams-e6d4f.cloudfunctions.net/callables-getUser', {
        method: 'POST'
      })*/

      const response = await getUser()
      console.log({ response })
    }
  }
}
</script>`
